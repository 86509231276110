import React from 'react'
import Main from '../../layouts/Main'
import { Transition, HeroSubpage, SectionWorkList } from '../../components/'
import { ScrollSectionProvider } from '../../context/ScrollContext'
import { workListMockData } from '../../misc/mockData'

const Work = ({ data, ...props }) => {
  const sections = []

  return (
    <ScrollSectionProvider value={sections} >
      <Main redFooter>
        <Transition>
          <HeroSubpage
            title={'Where design meets code'}
            subheadline={'We combine them together forever into oneness.'}
            color={{ color: '#ff0000' }}
          />
          <SectionWorkList
            title={'Work'}
            subtitle={'this is the cream of the top.'}
            link={'/'}
            linkText={'See all work'}
            items={workListMockData}
          />
        </Transition>
      </Main>
    </ScrollSectionProvider>
  )
}

export default Work
