import React from 'react'
import Main from '../../layouts/Main'
import { Transition, SectionWorkList } from '../../components/'
import { ScrollSectionProvider } from '../../context/ScrollContext'
import { workListMockData } from '../../misc/mockData'

const ArticleList = ({ data, ...props }) => {
  const sections = []

  return (
    <ScrollSectionProvider value={sections} >
      <Main redFooter>
        <Transition>
          <SectionWorkList
            title={'Article list'}
            subtitle={'Subtitle for article list. This could be the blog layout f.ex.'}
            items={workListMockData}
            largeTitle
          />
        </Transition>
      </Main>
    </ScrollSectionProvider>
  )
}

export default ArticleList
