/* eslint-disable */
export const heroMockData = {
  title: 'Bottom-line digital',
  subtitle: 'A carefully designed user experience is damn good business'
}

export const projectMockData = {
  title: 'Project',
  subtitle: 'Customer service web',
  bgImage: 'https://picsum.photos/id/1057/6016/4016',
  image: 'https://picsum.photos/id/873/495/685',
  infoTitle: 'Revolution in digital services ',
  infoText: `<p>VÍS Insurance set itself an ambitious digital service policy to make it easier for customers to access information and process themselves at their customer service web.</p>
  <p>The main focus was to make everything easier and accessible for the customer so he could finish his buisness without getting help from customer service.</p>
  <p>Not only is this a new bold design for the customer service web but also a few new electronic application processes and major improvement of the digital appearance of the company.</p>`,
  clientLogo: 'https://picsum.photos/id/873/210/135',
  year: '2019 - on going',
  expertise: ['Brand Experience Strategy', 'Brand Strategy & Positioning', 'Creative Direction', 'Mobile/ Responsive Design', 'UI/UX Design', 'Front-end Development', 'Back-end Development'],
  link: 'http://test.is',
  linkText: 'Visit Project',
  color: {color: '#C20E1A'},
  projectImages: [
    { image: 'https://picsum.photos/id/873/1000/750', mobileImg: 'https://picsum.photos/id/1057/418/785',  text: 'Sign-in screen for mobile and settings page when logged in. ', alt: 'image' },
    { image: 'https://picsum.photos/id/873/1469/1085', text: 'Easy to see what documents needs to be signed and what has already been signed. This is a brand new feature  for the customer service web.', alt: 'image' }
  ],
  quote: {
    title: 'A few words from the design team',
    text: 'When the path of Sýn and Vodafone got re-united again in 2016, we immediately found good dynamics between the teams and the result is that. At Kosmos & Kaos, the projects are ambitious and there are exceptionally creative people with extensive experience from the web business. The partnership has been instructive and above all fun.',
    image: 'https://picsum.photos/id/467/100/100',
    alt: 'mynd af dóti',
    name: 'Auður Karítas Þórhallsdóttir',
    job: 'Webmaster at Sýn '
  },
  twoColPhone: {
    title: 'Report all claims',
    text: '<p>Now there is an easy process to report all claims on the customer service web. Simply choose your category of claim, then log in and fill in the form in just a few minuites.</p><p>It’s a easy as that!</p>',
    image: 'https://picsum.photos/id/873/495/685'
  },
  workItems: [
    { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/464/595/695', alt: 'mynd af dóti', link: '/project' },
    { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/64/595/695', alt: 'mynd af dóti', link: '/project' }
  ]
}

export const aboutUsMockData = {
  title: 'We are here to create',
  subtitle: 'a digital experience and all kinds of noticeable excellence.',
  intro: {
    title: 'Design is our driving force.',
    lead: 'In the beginning there was only chaos, the formless void from which all creation came, and chaos created cosmos, the universe with a form.',
    text: '<p>We are a digitally orientated design agency and we follow our heart in all our projects.</p> <p>The agency was established in March 2010, as time went by, the agency went from taking care of projects at the kitchen table to opening two branch offices, one in Reykjanesbær and another in Reykjavík. </p><p>In addition to the recognition and awards we have received for well-executed work, Kosmos & Kaos has been recognised as a family friendly company and has attracted attention for its dynamic human resources polices that undergo continuous review.</p>',
    image: 'https://picsum.photos/id/873/490/590',
  }
}

export const servicesMockData = {
  title: 'We launch digital environ­ments',
  subtitle: 'Into the Kosmos - and help companies provide exemplary services.',
  intro: {
    title: 'Digital, think digital.',
    lead: 'Company business environments are changing rapidly. Company appearance and services in digital media have an ever-greater impact on their competitiveness and market position. ',
    text: '<p>The foremost companies of the future will be those who think digitally.</p><p>We are here to help you into that process and together we create next generation of digital brands and experiences. </p><p>We strive to create beautiful and functioning solutions that you can be proud of.</p>',
    image: 'https://picsum.photos/id/873/490/590'
  }
}

export const workListMockData = [
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/464/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/64/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/55/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/44/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/42/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/24/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/65/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/74/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/764/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/164/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/94/595/695', alt: 'mynd af dóti', link: '/project' },
  { title: 'The New Face of Friendly Investment Banking', client: 'Arion Banki', 'image': 'https://picsum.photos/id/84/595/695', alt: 'mynd af dóti', link: '/project' }
]

export const mockStaffData = [
  {name: '1Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/7/440/660', info: {location: 'Reykjavík, Iceland', audio: 'All kinds of different stuff.', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '2Guðmundur Sigurðsson', title: 'Founder, Creative irector', 'image': 'https://picsum.photos/id/8/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '3Arnór Heiðar Sigurðsson', title: 'CTO', 'image': 'https://picsum.photos/id/10/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '4Ingi Már Elvarsson', title: 'Country manager', 'image': 'https://picsum.photos/id/11/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '5Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/12/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '6Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/13/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '7Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/14/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '8Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/15/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '9Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/16/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '10Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/17/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '11Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/18/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '12Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/19/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '13Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/20/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '14Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/21/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: '15Inga Birna Ragnarsdóttir', title: 'CEO', 'image': 'https://picsum.photos/id/22/440/660', info: {location: 'Reykjavík, Iceland', audio: 'audio', reads: 'readsd', apps: 'appps', office: 'officeface', bag: 'in my bag stuff', inspiration: 'inspiration', instagram: 'http://test.is', linkedin: 'http://test.is', email: 'email@kosmosogkaos.is'}},
  {name: 'This could be you', title: 'Check out available positions', 'image': 'https://picsum.photos/id/23/440/660', link: '/'}
]

export const logosMockData = [
  { name:'client', image: 'https://picsum.photos/180/40', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/46', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/71/42', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/177/40', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/50', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/43', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/50', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/40', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/50', link:'http://test.is'},
  { name:'client', image: 'https://picsum.photos/175/40', link:'http://test.is'},
  { name:'client', image: 'https://www.w3.org/Icons/SVG/svg-w3c-h.svg', link:'http://test.is'},
  { name:'client', image: 'https://upload.wikimedia.org/wikipedia/en/thumb/c/cc/Vodafone_2017_logo.svg/1280px-Vodafone_2017_logo.svg.png', link:'http://test.is'}
]

export const serviceStepMockData = {
  title: 'Design',
  text: 'If we told you that we thought design was the most important thing in life, we would be exaggerating – but only a little.',
  steps: [
    { nr: '1.', text: 'Research has shown that companies that place a high value on design perform better, grow faster and have a significant competitive advantage over those that don’t.[1] This applies to companies in a wide range of industries and is especially relevant when it comes to digital user experience. With business now undergoing a rapid shift to digital, the bottom-line value of design has never been greater.'},
    { nr: '2.', text: 'So at the beginning of every project we are understandably excited. Along with figuring out the strategy and setting a general course for the project, we start sharpening our pencils and unpacking the design-related challenges and possibilities involved. Our approach is grounded in proven methods and principles, with a little twist of our own, and keeps evolving along with the changing landscape of digital media.'}
  ],
  footnotes: [
    {
      text: '[1] https://www.mckinsey.com/business-functions/mckinsey-design/our-insights/the-business-value-of-design',
      link: 'https://www.mckinsey.com/business-functions/mckinsey-design/our-insights/the-business-value-of-design'
    },
    {
      text: '[1] https://www.mckinsey.com/business-functions/mckinsey-design/our-insights/the-business-value-of-design',
      link: 'https://www.mckinsey.com/business-functions/mckinsey-design/our-insights/the-business-value-of-design'
    }
  ]
}

import extendedImgTemp from '../assets/images/mynd_extended.png'

export const mockDataServiceItems = {
  title: 'Services.',
  lead: 'nice people make nice projects.',
  link: '/',
  linkText: 'See all services',
  items: [
    { title: 'Design', 'image': extendedImgTemp, text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off. ', alt: 'mynd af dóti', link: '/', linkText: 'More about design', extend: true },
    { title: 'Development', 'image': 'https://picsum.photos/id/465/595/695', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off.</p> ', alt: 'mynd af dóti', link: '/', linkText: 'More about design' },
    { title: 'Consulting', 'image': 'https://picsum.photos/id/466/595/695', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off. </p>', alt: 'mynd af dóti', link: '/', linkText: 'More about design' },
    { title: 'A few words from a happy client', 'image': 'https://picsum.photos/id/467/100/100', text: 'When the path of Sýn and Vodafone got re-united again in 2016, we immediately found good dynamics between the teams and the result is that. At Kosmos & Kaos, the projects are ambitious and there are exceptionally creative people with extensive experience from the web business. The partnership has been instructive and above all fun.', alt: 'mynd af dóti', name: 'Auður Karítas Þórhallsdóttir', job: 'Webmaster at Sýn ', type: 'quote' },
    { title: 'Design Sprint', 'image': 'https://picsum.photos/id/468/595/695', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off.</p>', alt: 'mynd af dóti', link: '/', linkText: 'More about design' }
  ]
}

export const mockDesignSprintItems = {
  title: 'Execution.',
  lead: 'this is how we do it in 4 days.',
  link: '/',
  linkText: 'See all services',
  items: [
    { title: 'The big picture.', 'image': extendedImgTemp, imgTag: 'day 1', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off. </p><h4>Projects of the day</h4><ul><li>Fyrstu drög að notendaflæði</li><li>Skrifa, krassa, skissa og teikna</li></ul> ', alt: 'mynd af dóti', extend: true },
    { title: 'Decision making.', 'image': 'https://picsum.photos/id/465/595/695', imgTag: 'day 2', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact.</p><p>Quality design that works ensures a positive user experience and it always pays off. </p><h4>Projects of the day</h4><ul><li>Fyrstu drög að notendaflæði</li><li>Skrifa, krassa, skissa og teikna</li><li>Skrifa, krassa, skissa og teikna</li></ul>', alt: 'mynd af dóti'},
    { title: 'Prototyping.', 'image': 'https://picsum.photos/id/466/595/695', imgTag: 'day 3', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off. </p><h4>Projects of the day</h4><ul><li>Fyrstu drög að notendaflæði</li><li>Skrifa, krassa, skissa og teikna</li></ul> ', alt: 'mynd af dóti'},
    { title: 'User testing.', 'image': 'https://picsum.photos/id/467/100/100', imgTag: 'day 4', text: '<p>We design user experiences for websites, smart applications and everywhere else where a screen and a person interact. Quality design that works ensures a positive user experience and it always pays off. </p><h4>Projects of the day</h4><ul><li>Fyrstu drög að notendaflæði</li><li>Skrifa, krassa, skissa og teikna</li></ul> ', alt: 'mynd af dóti' },
    { title: 'A few words from a happy client', 'image': 'https://picsum.photos/id/467/100/100', text: 'When the path of Sýn and Vodafone got re-united again in 2016, we immediately found good dynamics between the teams and the result is that. At Kosmos & Kaos, the projects are ambitious and there are exceptionally creative people with extensive experience from the web business. The partnership has been instructive and above all fun.', alt: 'mynd af dóti', name: 'Auður Karítas Þórhallsdóttir', job: 'Webmaster at Sýn ', type: 'quote' }
  ]
}

export const careersMockData = {
  heroTitle: 'You could be our new talent.',
  heroSubTitle: 'We are always on the lookout for a fresh talent that shares our vision to create beutiful things.',
  heroImage: 'https://images.unsplash.com/photo-1528818955841-a7f1425131b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1778&q=80',
  title: 'Are you the one?',
  lead: '<p>We are committed to create an inclusive employee experience for all. We belive that our work is at its best when everyone feels free to be their most authentic self.</p><p>We collect intelligent, sweet, smiling, talented people who are capable of communicating and create wonderful stuff within teams.</p>',
  positions: {
    title: 'Currently open positions',
    text: '<p>But you can always pitch yourself for something else, see our form below.</p>',
    positions: [
      { title: 'Design Lead, Stockholm', link: '/' },
      { title: 'Senior Copywriter, Stockholm', link: '/' },
      { title: 'Program Director, Iceland', link: '/' }
    ]
  },
  application: {
    title: 'Resumé or portfolio. Or  better yet, send both. ',
    text: '<p>If you prefer you can send an e-mail to <a href="mailto:jobs@kosmosandkaos.com">jobs@kosmosandkaos.com</a></p>'
  }
}

export const contactMockData = {
  title: 'Hello there',
  lead: 'Got a project in mind? We are always up for a conversation.',
  gallery1: {
    title: 'Reykjavík',
    subtitle: 'check out the office in Iceland.',
    images: [
      { src: 'https://picsum.photos/id/500/590/690', alt: 'office' },
      { src: 'https://picsum.photos/id/501/596/509', alt: 'office' },
      { src: 'https://picsum.photos/id/502/596/509', alt: 'office' },
      { src: 'https://picsum.photos/id/503/596/509', alt: 'office' }
    ]
  },
  gallery2: {
    title: 'Stockholm',
    subtitle: 'check out the office in Sweden.',
    images: [
      { src: 'https://picsum.photos/id/504/590/690', alt: 'office' },
      { src: 'https://picsum.photos/id/505/596/509', alt: 'office' },
      { src: 'https://picsum.photos/id/506/596/509', alt: 'office' },
      { src: 'https://picsum.photos/id/507/596/509', alt: 'office' }
    ]
  }
}

export const instaMockData = {
  title: 'Insta.',
  subtitle: 'we like to post some nice things.',
  images: [
    { src: 'https://picsum.photos/id/504/590/590', alt: 'office', link: 'http://test.is'},
    { src: 'https://picsum.photos/id/505/590/590', alt: 'office', link: '/'},
    { src: 'https://picsum.photos/id/506/590/590', alt: 'office', link: '/'},
    { src: 'https://picsum.photos/id/507/590/590', alt: 'office', link: '/'},
    { src: 'https://picsum.photos/id/504/590/590', alt: 'office', link: '/'},
    { src: 'https://picsum.photos/id/505/590/590', alt: 'office', link: '/'},
    { src: 'https://picsum.photos/id/506/590/590', alt: 'office', link: '/'},
    { src: 'https://picsum.photos/id/507/590/590', alt: 'office', link: '/'}
  ]
}

export const articleMockData = {
  title: 'Article title comes here',
  subtitle: 'Subtitle comes here. Lorem ipsum dolor sit amet og allt það.',
  images: [
    { src: 'https://picsum.photos/id/504/593/669', alt: 'office'},
    { src: 'https://picsum.photos/id/507/596/895', alt: 'office'},
    { src: 'https://picsum.photos/id/505/590/417', alt: 'office'},
    { src: 'https://picsum.photos/id/506/590/417', alt: 'office'},
    { src: 'https://picsum.photos/id/504/590/361', alt: 'office'},
    { src: 'https://picsum.photos/id/505/590/361', alt: 'office'}
  ]
}
/* eslint-enable */
